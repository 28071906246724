/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
  window.addEventListener('scroll', onWindowScroll);
  let menuHeader = document.querySelector('header');
  if(Boolean(menuHeader)) {
    function onWindowScroll() {
      if(window.document.scrollingElement.scrollTop > 150){
        menuHeader.classList.add("sticky");
      }
      else { 
        menuHeader.classList.remove("sticky")
      }
    }
  }
}
/***********Mega Menu ********************* */
const handlePopper = (anchor, dropdown, left) => {
  let popper = Popper.createPopper(anchor, dropdown, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: () => {
            if ( window.document.scrollingElement.scrollTop < 150) {
              return [left, 60];
            } else {
              return [left, 40];
            }
          },
        },
      },
    ],
  });
  return popper;
}
window.addEventListener('DOMContentLoaded', ()=> {
const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];  
let megaMenu = document.querySelectorAll('.dropdown-mega-menu-item');
if (Boolean(megaMenu)) {
  megaMenu.forEach((item) => {
      let anchor = item.querySelector('.dropdown-mega-menu-link');
      let dropdown = item.querySelector('.dropdown-mega-menu');
      let popper = handlePopper(anchor, dropdown, 300);
      item.querySelectorAll('a[data-open-dropdown-level]').forEach((elem) => {
        elem.addEventListener('click', () => {
          let newLevel = elem.getAttribute('data-open-dropdown-level');
           openLevel(newLevel);
        });
      });
      item.querySelectorAll('.dropdown-mega-menu-link').forEach((elem) => {
        showEvents.forEach((event) => {
          item.addEventListener(event,() => {
            item.querySelector('.dropdown-mega-menu').classList.add('visible');
            window.setTimeout(function () {
              document.querySelector('.nav-link-back') ? openLevel('goback') : '';
            }, 300);
          });
        });
        hideEvents.forEach((event) => {
          item.addEventListener(event, () => {
            item.querySelector('.dropdown-mega-menu').classList.remove('visible');
          });
        });
      });
      function openLevel(level) {
        let dataLevel = item.querySelector('div[data-level]');
        let dataLevelPlus = item.querySelector('div[data-level=' + level + ']');
        let dataLevelSub = item.querySelector('div[data-level="plusLavel"]');
        if(level == 'plusLavel') {
          dataLevelPlus.classList.remove('hidden');
          dataLevel.classList.add('hidden');
        }
        if(level == "goback") {
          dataLevelSub.classList.add('hidden');
          dataLevel.classList.remove('hidden');
        }
        popper.update();
      }
  });
}

});
//////********** Simple Header Menu ********************///////////
window.addEventListener('DOMContentLoaded', ()=> {
  const showEvents = ['mouseenter', 'focus'];
  const hideEvents = ['mouseleave', 'blur'];  
  let simpleLink = document.querySelectorAll('.simple-dropdown-menu');
  let indexArr = [];
  if(Boolean(simpleLink)) {
  simpleLink.forEach((item, index) => {
    let simpleTooltip = item.querySelector('.simple-dropdown-menu-tooltip');
    let anchor = item.querySelector('.nav-link');
    handlePopper(anchor, simpleTooltip, 0);
    const hide = () => {
      if(index !== indexArr[0]) {
        let num = indexArr[0];
        simpleLink[num].querySelector('.simple-dropdown-menu-tooltip').classList.remove('visibil');
        indexArr = [index];
      } else {
        indexArr = [index];
      }
    }
    showEvents.forEach((event) => {
      item.addEventListener(event, () => {
        if(Boolean(!simpleTooltip.classList.contains('visibil'))) {
        simpleTooltip.classList.add('visibil');
        }
        indexArr.push(index);
        hide();
      });
    });
    hideEvents.forEach((event) => {
      item.addEventListener(event, () => {
        if(Boolean(simpleTooltip.classList.contains('visibil'))) {
          simpleTooltip.classList.remove('visibil');
        }
      });
    })
   });
  }
 });
////////********* Support Btn ****************////////////////
document.addEventListener("DOMContentLoaded", supportBtn);
function supportBtn() {
  let dropDownSupportBtn = document.querySelectorAll('.support-dropdown');
  const show = () => {
    if(window.innerWidth > 1150) {
    dropDownSupportBtn.forEach((item) => {
      try {
        item.querySelector('.support-dropdown__btn__list__items').classList.add('active');
        item.querySelector('.btn-content > i').classList.add('active');
      } catch (error) {
        console.log(error);
      }
    });
  }
  }
  const hide = () => {
    if(window.innerWidth > 1150) {
    dropDownSupportBtn.forEach((item) => {
      try {
        item.querySelector('.support-dropdown__btn__list__items').classList.remove('active');
        item.querySelector('.btn-content > i').classList.remove('active');
      } catch (error) {
        console.log(error);
      }
    });
  }
  }
  dropDownSupportBtn.forEach((item) => {
      if(window.innerWidth < 1150) {
        item.querySelector('.btn-content').addEventListener('click', () => {
          try {
            item.querySelector('.support-dropdown__btn__list__items').classList.toggle('active');
            item.querySelector('.btn-content > i').classList.toggle('active');
          } catch (error) {
            console.log(error);
          }
        });
    }
  });
  const showEvents = ['mouseenter', 'focus'];
  const hideEvents = ['mouseleave', 'blur'];
  showEvents.forEach((event) => {
    dropDownSupportBtn.forEach((item) => {
      item.addEventListener(event, show);
    });
  });
  hideEvents.forEach((event) => {
    dropDownSupportBtn.forEach((item) => {
      item.addEventListener(event, hide);
    });
  });
}
//////////////Search Form Header ///////////////////
document.addEventListener("DOMContentLoaded", openSearchForm);
function openSearchForm() {
  let openSearchForm = document.querySelectorAll('.search-form-open');
  let closeSearchForm = document.querySelectorAll('.close-search-form');
  let searchForm = document.querySelectorAll('.search-form');
  let headerMenu = document.querySelector('.navbar-header');
  openSearchForm.forEach((elem) => {
    elem.addEventListener('click', () => {
      elem.classList.add('hidden');
      headerMenu.classList.add('hidden');
      searchForm.forEach((item) => {
        item.classList.add('active');
      });
    });
  });
  closeSearchForm.forEach((elem) => {
    elem.addEventListener('click', () => {
      searchForm.forEach((item) => {
        item.classList.remove('active');
      });
      openSearchForm.forEach((item) => {
        item.classList.remove('hidden');
      });
      headerMenu.classList.remove('hidden');
    });  
  })
}
//////////////TAB MENU //////////////////////////
const tabMenu = (
  tabsMenuSelector,
  tabsContentSelector,
  ) => {
  let tabs = document.querySelectorAll(`${tabsMenuSelector}`);
  let tabsContent = document.querySelectorAll(`${tabsContentSelector}`);
  tabs.forEach((tab, index) => {
    tab.addEventListener('click', (event) => {
      let indexThis = index;
       event.preventDefault();
       tab.classList.add('active');
       tabsContent[index].classList.add('active');
       tabsContent.forEach((item) => {
        item.classList.add('active');
       });
       handleRemove(indexThis, tabs, tabsContent);
      });
  });
  const handleRemove = (indexThis, tabs, tabsElemContent) => {
    tabs.forEach((tab, index) => {
      if(index != indexThis) {
        tab.classList.remove('active');
      }
    });
    tabsElemContent.forEach((tab, index) => {
      if(index != indexThis) {
        tab.classList.remove('active');
      }
    });
  }
}
tabMenu(
  '.tabs-solution__list > li',
  '.tabs-content-item',
);
tabMenu(
  '.availability-tabs__navi__list > li',
  '.availability-tabs__item',
);
tabMenu(
  '.date-line-list__item',
  '.date-line-content__item',
);
tabMenu(
  '.tabs-btn__item',
  '.tabs-contents__item',
);
/////////////// back to top /////////////////////////
const scrollToTop = () => {
  let backToTopBtn = document.querySelector('.back-to-top');
  if(backToTopBtn) {
    let vHUser = document.documentElement.clientHeight;
    let userHScroll = window.document.scrollingElement.scrollTop;
    if(userHScroll < vHUser) {
      backToTopBtn.classList.remove('visibil');
    } else {
      backToTopBtn.classList.add('visibil');
    }
    backToTopBtn.addEventListener('click', () => {
      window.scrollBy({
        top: -userHScroll,
        behavior: 'smooth'
    });
    })
  } else {
    return '';
  }
}
window.addEventListener('scroll', scrollToTop);
//// accordion /// 
    function accordion() {
        const items = document.querySelectorAll('.accordion__item-trigger');
        items.forEach(item => {
            item.addEventListener('click', () => {
                const parent = item.parentNode
                if (parent.classList.contains('active')) {
                    parent.classList.remove('active');
                } else {
                    document
                        .querySelectorAll('.accordion__item')
                        .forEach(child => child.classList.remove('active'));
                    parent.classList.add('active');
                }
            })
        })
    }
    accordion();
    /////////////////////////
    const tabsLoadbtn = () => {
      let tabsBtn = document.querySelectorAll('.tabs-btn__item');
      const loadMore = () => {
      let tabactive = document.querySelector('.tabs-contents__item.active');
      let cardsActive = tabactive.querySelectorAll('.tabs-contents__item__card-list__item.active');
      let cards = tabactive.querySelectorAll('.tabs-contents__item__card-list__item');
      let wrapBtn = tabactive.querySelector('.tabs-contents__item__load-more');
      let btn = tabactive.querySelector('.tabs-contents__item__load-more');
      if(cards.length > 6 && cardsActive.length < 7) {
          wrapBtn.classList.add('active');
      }
      btn.addEventListener('click', () => {
          cards.forEach((card, index) => {
          if(index > 6) {
              card.classList.add('active');
          }
      });
          wrapBtn.classList.remove('active')
      });
  }
      window.addEventListener('DOMContentLoaded', () => { 
          loadMore();
      });
      tabsBtn.forEach((btn) => {
          btn.addEventListener('click', () => {
              loadMore();
          });
      });
  }
/// Burger menu
const burgerMenu = () => {
  const burger = document.querySelectorAll(".burger-menu");
    const showMenu = () => {
      document.querySelector(".burger-menu").classList.toggle("show");
      document.querySelector(".navbar-header-mobail").classList.toggle("show");
      document.querySelector("body").classList.toggle('active');
  };
  for (i = 0; i < burger.length; i++) {
    burger[i].addEventListener("click", showMenu, !1);
  }
};
burgerMenu();
///// Mobail Menu
const mobileMenu = () => {
  if(window.innerWidth < 1150) {
    const firstLevelItesSimple = document.querySelectorAll('.nav-mobail-item');
    firstLevelItesSimple.forEach((item) => {
      item.querySelector('.right-arrow').addEventListener('click', () => {
        if(item.getAttribute('class').includes('dropdown-mobail-menu-item')) {
          hidefirstLevelSimpleItems();
          item.classList.toggle('visible');
          handleNextLavelMenu();
        } else {
            item.classList.toggle('visible');
          }
      })
    });

  }
}
const handleNextLavelMenu = () => {
  let menuList = document.querySelectorAll('.first-lavel-mobail-menu > .nav');
  menuList.forEach((item) => {
    item.querySelector('.right-arrow').addEventListener('click', () => {
      item.classList.toggle('show');
      let activeLavel = document.querySelector('.nav-mobail-item.dropdown-mobail-menu-item.visible');
      let activeArrow = document.querySelector('.nav-mobail-item.dropdown-mobail-menu-item.visible > span');
      let activeLink = activeLavel.querySelector('.nav-link.dropdown-mobail-menu-link');
      let notShowList = activeLavel.querySelectorAll('.nav');
      activeLink.classList.toggle('hidden');
      activeArrow.classList.toggle('hidden');
      notShowList.forEach((elem) => {
        let showList = activeLavel.querySelectorAll('.nav.show');
        if(showList.length > 0) {
          if(!elem.getAttribute('class').includes('show')) {
            elem.classList.add('hidden');
          } 
        } else {
          elem.classList.remove('hidden');
        }
      });
      let plusLavel = item.querySelector('a[data-open-dropdown-level="plusLavel"]');
      if(plusLavel) { plusLavel.addEventListener('click', () => {
          item.querySelector('.plusLavel-list').classList.toggle('active');
        });}
    });
  });
}
const hidefirstLevelSimpleItems = () => {
  const firstLevelItesSimple = document.querySelectorAll('.nav-mobail-item.simple-menu');
  firstLevelItesSimple.forEach((item) => {
      item.classList.toggle('hidden');
  });
}
mobileMenu();
////////////// google tag manager
const handleEventgoogleTagManager = () => {
  const googleTagManager = (pdf_name) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    "event": "pdf_download",
    "pdfName": pdf_name,
    });
    };
    const eventBtn = document.querySelectorAll('.downloads-row__item__button > a');
    const eventWBtn = document.querySelectorAll('.downloads-row__item__buttons > a');
    const eventWBannerBtn = document.querySelectorAll('.banner-list__item > .banner-btn');
    if(eventBtn) {
      eventBtn.forEach((item) => {
        item.addEventListener('click', () => {
            let pdf_name_arr = item.getAttribute('href').split('/');
            let pdf_name = pdf_name_arr[pdf_name_arr.length - 1];
            googleTagManager(pdf_name);
        });
      });
    }
    if(eventWBtn) {
      eventWBtn.forEach((item) => {
        item.addEventListener('click', () => {
            let pdf_name_arr = item.getAttribute('href').split('/');
            let pdf_name = pdf_name_arr[pdf_name_arr.length - 1];
            googleTagManager(pdf_name);
        });
      });
    }
    if(eventWBannerBtn) {
      eventWBannerBtn.forEach((item) => {
        item.addEventListener('click', () => {
            let pdf_name_arr = item.getAttribute('href').split('/');
            let pdf_name = pdf_name_arr[pdf_name_arr.length - 1];
            googleTagManager(pdf_name);
        });
      });
    }
}
handleEventgoogleTagManager();

///// Locale
const setLocale = () => {
  localStorage.setItem('geoLocale', value);
  localStorage.setItem('browserLocale', value)
}

const getLocale = () => {
    let geoLocale = localStorage.getItem('');
    let browserLocale = localStorage.getItem('');
    if(geoLocale && browserLocale) {
      return true;
    } else {
      return false;
    }
}

const inditificateLangUser = () => {
  let firstLocationUrl = window.location.href;
  let redirectUser = window.location.origin + '/hub/';
  if(redirectUser !== window.location.href) {
    window.location.href = redirectUser;
  }
}
window.activeElement("DOMContentLoaded", inditificateLangUser);